var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"groupForm",staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"fr.lang","rules":"required","name":"The Language"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"lang","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Language'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Language'),"list":_vm.builderData.languageList,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.lang),callback:function ($$v) {_vm.$set(_vm.formData, "lang", $$v)},expression:"formData.lang"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"vid":"fr.target","rules":"required","name":"The Target"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"lang","size":"large","filterable":"","clearable":"","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Sent From'),"error":failed ? errors[0]: null,"input-classes":'select-default',"label":_vm.$store.getters['languageMixin/getStringFromLanguage']('Sent From'),"list":_vm.builderData.targetsList,"listItemLabel":'label',"listItemValue":'value'},model:{value:(_vm.formData.target),callback:function ($$v) {_vm.$set(_vm.formData, "target", $$v)},expression:"formData.target"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Action')))]),_c('ValidationProvider',{attrs:{"vid":"fr.action","rules":"","name":"The Action"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"name":"lang","type":"text","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Action'),"error":failed ? errors[0]: null},model:{value:(_vm.formData.action),callback:function ($$v) {_vm.$set(_vm.formData, "action", $$v)},expression:"formData.action"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Subject')))]),_c('ValidationProvider',{attrs:{"vid":"fr.subject","rules":"required","name":"The Subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('Subject'),"error":failed ? errors[0]: null},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Email content in HTML format')))]),_c('editor',{attrs:{"api-key":_vm.editorKey,"init":_vm.editorConfig},on:{"onKeyUp":_vm.generatePlainText},model:{value:(_vm.formData.html),callback:function ($$v) {_vm.$set(_vm.formData, "html", $$v)},expression:"formData.html"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"card-label"},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Email content in plain text format')))]),_c('div',{staticClass:"generate-text"},[_c('input',{attrs:{"type":"checkbox","id":"generate-text"}}),_c('label',[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Automatically generate plain text format')))])]),_c('ValidationProvider',{attrs:{"vid":"fr.text","rules":"required","name":"The Text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","placeholder":_vm.$store.getters['languageMixin/getStringFromLanguage']('text'),"error":failed ? errors[0]: null},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Submit'))+" ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/email-templates/list')}}},[_vm._v(_vm._s(_vm.$store.getters['languageMixin/getStringFromLanguage']('Cancel'))+" ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }